<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.202" x2="0.901" y1="1" y2="-0.091">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="Url" transform="translate(-130 -368)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(130 368)" width="60"/>
    <path d="M19.137,10.863a8.9,8.9,0,0,1,.021,12.574l-.021.022L15.2,27.4A8.906,8.906,0,0,1,2.6,14.8l2.174-2.174a.937.937,0,0,1,1.6.621,10.8,10.8,0,0,0,.568,3.089.943.943,0,0,1-.222.973l-.767.767a4.219,4.219,0,1,0,5.928,6l3.937-3.937a4.218,4.218,0,0,0,0-5.967,4.389,4.389,0,0,0-.606-.5.94.94,0,0,1-.407-.739A2.334,2.334,0,0,1,15.5,11.19l1.234-1.234a.941.941,0,0,1,1.206-.1,8.934,8.934,0,0,1,1.2,1.008ZM27.4,2.6a8.916,8.916,0,0,0-12.6,0L10.862,6.542l-.021.022a8.908,8.908,0,0,0,1.223,13.581.941.941,0,0,0,1.206-.1L14.5,18.81a2.334,2.334,0,0,0,.685-1.746.94.94,0,0,0-.407-.739,4.389,4.389,0,0,1-.606-.5,4.218,4.218,0,0,1,0-5.967l3.937-3.937a4.219,4.219,0,1,1,5.928,6l-.767.767a.943.943,0,0,0-.222.973,10.8,10.8,0,0,1,.568,3.089.937.937,0,0,0,1.6.621L27.4,15.2a8.916,8.916,0,0,0,0-12.6Z" data-name="Icon awesome-link" id="Icon_awesome-link" style="fill: url(#linear-gradient);" transform="translate(145 383)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>